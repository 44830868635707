const setProperty = (key, value) => {
  //@ts-ignore
  if (window.clarity) {
    //@ts-ignore
    window.clarity('set', key, value)
  }
}

const claritySetUserProperty = (key, value) => {
  setProperty(key, value)
}

export { claritySetUserProperty }
